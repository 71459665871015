<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>Contact Us cheapcomforts.com™ </h1>
                    <p>We search the top sites to help travelers book the perfect cheap Comforts </p>
                </div>
                <div class="content-section center">
                    <form  class="send-message">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="email" class="form-control" id="name" :placeholder="$t('Propertis.enter_email')">
                            <!-- <div class="invalid-feedback">{{ formErrors.email }}</div> -->
                        </div>
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" id="email" :placeholder="$t('Propertis.enter_email')">
                          <!-- <div class="invalid-feedback">{{ formErrors.email }}</div> -->
                        </div>
                        <div class="form-group">
                          <label for="message">{{ $t('Propertis.Message') }}</label>
                          <textarea class="form-control" id="message" rows="5"
                            :placeholder="$t('Propertis.enter_message')"></textarea>
                          <!-- <div class="invalid-feedback">{{ formErrors.message }}</div> -->
                        </div>
                        <button type="submit" class="btn btn-primary">Send</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'contact_us.vue',
    components: {
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px !important;
}
.form-group label {
  font-size: 18px;
}
.form-control {
    display: block;
    width: 100%;
    height: 46px;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    color: #292929;
    background-color: #fff;
    background-image: none;
    border: 1px solid #676A7D !important;
    border-radius: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}
.send-message button.btn.btn-primary {
  font-size: 16px !important;
  line-height: 1.25rem;
  min-block-size: 2.25rem;
  padding: .25rem 1rem;
  background: #70d3ff !important;
  color: #fff;
  border: 1px solid #70d3ff;
  width: 100%;
  gap: .5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 30px !important;
}
textarea.form-control {
    height: 110px;
    padding: 15px;
}
.send-message button.btn.btn-primary:hover {
    background-color: #fff !important;
}
</style>